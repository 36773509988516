import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Interface = ({ myinterface }) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-sm-6" key={Math.random()}>
      <div className="single-solutions-box">
        <div className="icon" style={{ height: 110 }}>
          <GatsbyImage
            image={
              myinterface.frontmatter.featuredImage.childImageSharp
                .gatsbyImageData
            }
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Interface"
          />
        </div>
        <h3>
          <Link
            key={Math.random()}
            to={myinterface.frontmatter.link}
            target="_blank"
            rel="noreferrer"
          >
            {myinterface.frontmatter.title}
          </Link>
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: myinterface.html,
          }}
        />
        <p></p>
        <a
          href={myinterface.frontmatter.link}
          target="_blank"
          rel="noreferrer"
          className="view-details-btn"
        >
          {t("Zur Website")}
        </a>
      </div>
    </div>
  );
};

export default Interface;
