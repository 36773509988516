import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Interface from "../components/Services/Interface";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/App/SEO";

const InterfacePage = ({ data }) => {
  const { t } = useTranslation();
  const { edges } = data.interface;
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Schnittstellen")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Leistungen")}
      />
      <div className="customers-area pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <StaticImage
                src="../assets/images/sun-icon.jpg"
                alt="sunIcon"
                style={{ verticalAlign: "middle", marginRight: "5px" }}
                width={32}
                height={32}
              />
              {t("Interface")}
            </span>
            <h2>{t("Service - Interface - Title - 1")}</h2>
            <p>{t("Service - Interface - Text - 2")}</p>
          </div>

          <div className="row">
            {edges.map(({ node }) => (
              <Interface key={Math.random()} myinterface={node} />
            ))}{" "}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default InterfacePage;

export const interfaces = graphql`
  query ($language: String!) {
    interface: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(/interfaces/)/" }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            link
            title
            language
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 200, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Schnittstellen für ein komplettes System"
    description="Dank unseren Schnittstellen ermöglichen wir die Implementierung von Elektroautos, Steuerung von Wärmepumpen oder Mieterplattformen"
  />
);
